import React from 'react';
import {Link} from 'react-router-dom';
import './Contactus.scss';


const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
const Contactus = () => {
  return (
    <div className="contact-us">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-item s">
              <h2 className="headline ">Careers</h2>
              <div className="description">Gain a heritage.&nbsp; Leave a legacy.</div>
              <Link to="/careers" className="btni" onClick={scrollToTop}>
                Join Us
                </Link>
            </div>
          </div>
          <div className="theline"></div>
          <div className="col-md-6">
            <div className="contact-item">
              <h2 className="headline">Contact Us</h2>
              <div className="description">What can we help you achieve.</div>
              <Link to="/contact" className="btni" onClick={scrollToTop}>
                Speak With Us
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
